<script>
import { scale, precision } from './stores'

export let value = 1
export let label = 'Input'
export let name = label

let scaledValue = ''

$: scaledValue = (value / $scale).toFixed($precision).replace(/\.0+$/, '').replace(/(\.[1-9])0/, '$1')

let timer
function handleInput(event) {
  let val = event.target.value
  let parsed = Number(val)

  if (Number.isNaN(parsed)) {
    return
  }

  value = parsed * $scale

  timer = setTimeout(() => {
		clearTimeout(timer)
  }, 1000)
}
</script>

<label>
  <div>{label}</div>
  <input name={name} bind:value={scaledValue} on:input={handleInput} type="number">
</label>

<style>
label {
  font-weight: bold;
  text-align: left;
}

input {
  max-width: 5em;
}
</style>
