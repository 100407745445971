const mouldings = window.mouldings || [
  {
    sku: 'matte-black',
    name: 'Grenville Slim Matte Black',
    hex: '#222',
    price: 13,
  },
  {
    sku: 'shiny-black',
    name: 'Shiny Black',
    hex: '#111',
    price: 24,
  },
  {
    sku: 'white',
    name: 'White',
    hex: '#fefefe',
    price: 15,
  },
  {
    sku: 'matte-silver',
    name: 'Grenville Slim Matte Silver',
    hex: '#eee',
    price: 15,
  },
  {
    sku: 'shiny-silver',
    name: 'Shiny Silver',
    hex: '#eee',
    price: 20,
  },
  {
    sku: 'pewter',
    name: 'Pewter',
    hex: '#8e9294',
    price: 20,
  },
  {
    sku: 'matte-gold',
    name: 'Matte Gold',
    hex: '#CFB53B',
    price: 15,
  },
  {
    sku: 'shiny-gold',
    name: 'Shiny Gold',
    hex: '#CFB53B',
    price: 20,
  },
  {
    sku: 'wedgewood-blue',
    name: 'Wedgewood Blue',
    hex: '#4a79a5',
    price: 15,
  },
  {
    sku: 'brunswick-green',
    name: 'Brunswick Green',
    hex: '#1b4d3e',
    price: 15,
  },
]

export default mouldings
