<script>
import { frame } from './stores'
import matboards from './matboards'
import mouldings from './mouldings'

$: matHex = (matboards.find(m => m.sku === $frame.matSku) || matboards[0]).hex
$: mouldingHex = (mouldings.find(m => m.sku === $frame.mouldingSku) || mouldings[0]).hex

let wallWidth = 1280
let wallHeight = 1280

$: artPos = getRect($frame.artWidth, $frame.artHeight)
$: matPos = (() => {
  if ($frame.matSku === '') return artPos
  return {
    x: artPos.x - $frame.matSides,
    y: artPos.y - $frame.matTop,
    width: $frame.artWidth + 2 * $frame.matSides,
    height: $frame.artHeight + $frame.matTop + $frame.matBottom
  }
})()
$: mouldingPos = (() => {
  return {
    x: matPos.x - 10,
    y: matPos.y - 10,
    width: matPos.width + 20,
    height: matPos.height + 20
  }
})()

function getRect(width, height) {
  let centerX = wallWidth / 2
  let centerY = wallHeight / 2

  let y = centerY - (height / 2)
  let bottom = centerY + (height / 2)
  let x = centerX - (width / 2)
  let right = centerX + (width / 2)

  return { x, y, width, height }
}

let files

$: file = files && files[0]
$: img = (() => {
  if (file) {
    return URL.createObjectURL(file)
  }

  const width = $frame.artWidth / 2
  const height = $frame.artHeight / 2

  let orientation = 'portrait'
  if (width - height > 0) {
    orientation = 'landscape'
  }
  if (Math.abs(width - height) < 100) {
    orientation = 'squarish'
  }

  return `https://source.unsplash.com/random/${width}x${height}?$orientation=${orientation}`
})()
</script>

<div class="container">
  <label class="select-art" class:has-img="{img}">
    Select artwork
    <input type="file" name="art-image" class="hidden" bind:files>
  </label>
  <svg
    width={wallWidth}
    height={wallHeight}
    viewBox="0 0 {wallWidth} {wallHeight}"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <filter id="shadow">
        <feDropShadow dx="0.01" dy="10" stdDeviation="10" flood-color="#222" flood-opacity="0.3" />
        <feDropShadow dx="0.01" dy="20" stdDeviation="20" flood-color="#222" flood-opacity="0.1" />
      </filter>
    </defs>
    <rect
      fill="{mouldingHex}"
      {...mouldingPos}
      style="filter: url(#shadow)"
    ></rect>
    <rect
      fill="{matHex}"
      {...matPos}
    ></rect>
    {#if img}
      <image href="{img}" {...artPos} preserveAspectRatio="none"></image>
    {:else}
      <rect
        fill="#e4e4e4"
        {...artPos}
      >
      </rect>
    {/if}
  </svg>
</div>

<style>
  .container {
    height: 0;
    max-width: 100%;
    padding-bottom: 100%;
    position: relative;
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 100%;
    max-height: 100%;
    border: solid 1px #f2f2f2;
  }

  .hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap;
  }

  .select-art {
    text-decoration: underline;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  } 

  .has-img {
    top: 5%;
    transform: translate(-50%, 0%);
  }
</style>
