<div class="row">
  <div>
    <label>Quantity</label>
    <input type=number min=1 bind:value={quantity} />
  </div>
  <span class="total js-total" class:fade="{!$total}">
    {#if $total}
      ${$total}
    {:else}
      N/A
    {/if}
  </span>
  <button
    disabled="{!$valid}"
    on:click="{addToCart}"
    class="align-self-bottom"
  >
    Add to Cart
  </button>
</div>
<div class="js-sku small">
  { $sku }
</div>

<style>
  .row {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  input {
    max-width: 4em;
    text-align: center;
  }

  label {
    display: block;
    font-weight: bold;
  }

  .total {
    margin: 0.5em 1em 0.5em auto;
    line-height: 2em;
  }

  .fade {
    opacity: 0.2;
  }

  .small {
    font-size: 0.5em;
    color: #bbb;
    text-align: right;
    margin: 16px 16px 0 0;
  }
</style>

<script>
import { sku, total, title, valid, moulding } from './stores'

let quantity = 1

function addToCart(e) {
  e.preventDefault()

  let container = document.querySelector('.js-add-to-cart-container')

  let moulding = $moulding 

  if (moulding) {
    let currentForm = document.querySelector('form.cart')
    if (currentForm) {
      currentForm.parentNode.removeChild(currentForm)
    }
    container = document.querySelector('.js-add-to-cart-container')
    container.innerHTML = moulding.addToCartHtml
    container.querySelector('form').setAttribute('action', '')
  }

  try {
    document.querySelector('input[name="custom-options[title]"]').value = $title
  } catch(e) {
    debugger
    console.error('Failed to set custom Title', e)
  }

  try {
    document.querySelector('input[name="custom-options[sku]"]').value = $sku
  } catch(e) {
    debugger
    console.error('Failed to set custom SKU', e)
  }

  container.querySelector('input[name="quantity"]').value = quantity
  container.querySelector('input#nyp').value = $total

  document.querySelector('button[name="add-to-cart"]').click()
}

</script>
