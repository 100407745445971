<script>
  import { error, frame, sku, title, unit } from './stores'
  import matboards from './matboards'
  import mouldings from './mouldings'
  import ScaledInput from './ScaledInput.svelte'
  import Label from './Label.svelte'
  import AddToCart from './AddToCart.svelte'

  function save(key, value) {
    try {
      localStorage.setItem(key, value)
    } catch(e) {}
  }

  unit.subscribe(value => {
    save('framebuilder:unit', value)
  })

  sku.subscribe(value => {
    save('framebuilder:sku', value)
  })

  let backboard = 'none'
  if ($frame.hasBackboard) {
    backboard = 'backboard'
  }
  if ($frame.hasPerspex) {
    backboard = 'both'
  }

  function setBackboard(e) {
    switch (backboard) {
      case 'none':
        $frame.hasBackboard = false 
        $frame.hasPerspex = false 
        return
      case 'backboard':
        $frame.hasBackboard = true
        $frame.hasPerspex = false 
        return
      case 'both':
      default:
        $frame.hasBackboard = true
        $frame.hasPerspex = true
    }
  }
</script>

<form>
  <h3>{$title}</h3>
  <div class="row">
    <Label label="Frame Colour">
      <select bind:value={$frame.mouldingSku}>
        {#each mouldings as { sku, name }}
          <option value={sku}>{name}</option>
        {/each}
      </select>
    </Label>
  </div>


  <div class="row art-section">
    <ScaledInput label="Art width" bind:value={$frame.artWidth} />
    <ScaledInput label="Art height" bind:value={$frame.artHeight} />

    <select name="units" bind:value={$unit}>
      <option value="mm">mm</option>
      <option value="cm">cm</option>
      <option value="inch">inch</option>
    </select>
  </div>

  <div class="row mat-section">
    <Label label="Matboard" helpText="A matboard helps make your art stand out from the frame. You need to allow some overlap of the mat window on your artwork (5mm per side). If including a mat, subtract at least 10mm from your picture's dimensions for the Art width/height fields above">
      <div class="flex">
        <select bind:value={$frame.matSku}>
          {#each matboards as { sku, name }}
            <option value={sku}>{name}</option>
          {/each}
        </select>
        {#if $frame.matSku}
          <button class="btn--link" on:click={() => $frame.matSku = ''}>No matboard</button>
        {/if}
      </div>
    </Label>
  </div>

  {#if $frame.matSku}
    <div class="row">
      <ScaledInput label="Top" bind:value={$frame.matTop} />
      <ScaledInput label="Bottom" bind:value={$frame.matBottom} />
      <ScaledInput label="Sides" bind:value={$frame.matSides} />
    </div>
  {/if}

  {#if $error}
    <p class="error">
      {$error}
    </p>
  {/if}

  <div class="row">
    <Label label="Acrylic & Backboard">
      <select bind:value={backboard} on:change={setBackboard} data-test="backboardSelect">
        <option value="none">No Acrylic or Backboard</option>
        <option value="backboard">Include Backboard only</option>
        <option value="both">Include Acrylic & Backboard</option>
      </select>
    </Label>
  </div>

  <AddToCart />
</form>

<style>
  h3 {
    font-size: 1.4rem;
    min-height: 3em;
    line-height: 1.5;
  }

  form {
    margin: 1em;
    max-width: 100%;
    flex-shrink: 0;
  }

  .flex {
    display: flex;
  }

  .row {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 0.5em 0;
  }

  .error {
    background: #f00;
    color: white;
    padding: 1em;
    font-weight: bold;
  }

  .btn--link {
    background: none;
    border-color: transparent;
    text-decoration: underline;
    margin-left: auto;
  }

  .btn--help {

  }
</style>

