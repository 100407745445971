<script>
export let label = 'Input'
export let helpText = ''

let helpTextVisible = false

function toggleHelpText() {
  helpTextVisible = !helpTextVisible
}
</script>

<div style="width: 100%">
  <div>
    {#if helpText}
      <div class="row">
        <label>{label}</label>
        <button type="button" class="helpText" on:click={toggleHelpText}>(What's this?)</button>
      </div>
      {#if helpTextVisible}
      <p>
        {helpText}
      </p>
      {/if}
    {:else}
      <label>{label}</label>
    {/if}
  </div>
  <slot></slot>
</div>

<style>
label {
  text-align: left;
  font-weight: bold;
}

.row {
  display: flex;

}

.helpText {
  background: none;
  border: 0;
  text-decoration: underline;
  color: #707070;
  margin: 0 0.5rem;
  padding: 0;
  white-space: nowrap;
}
</style>
