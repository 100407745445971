import P from 'es6-promise'
P.polyfill()

import Framer from './App.svelte'

document.addEventListener('DOMContentLoaded', function() {
  const defaultVars = {
    sku: 'fakemat',
    price: 10000,
    hex: '333',
    name: 'Invalid Mat'
  }
  const {name, price, sku, hex} = window.vars || defaultVars
  window.vars = window.vars || defaultVars

  const target = document.querySelector('.framerapp')

  window.Framer = new Framer({
    target,
  })

  document.body.className += ' js-loaded'
  try {
    document.querySelector('.entry-summary').className += ' invisible'
    document.querySelector('.images').className += ' invisible'
  } catch(e) {}
})

