const mats = [
  {
    name: 'No matboard',
    sku: ''
  },
  {
    name: 'White',
    sku: 'white-mat',
    hex: '#fff',
  },
  {
    name: 'Off-white',
    sku: 'offwhite-mat',
    hex: '#fbfefe',
  },
  {
    name: 'Black',
    sku: 'black-mat',
    hex: '#222',
  },
]

export default mats

