<script>
import Renderer from './Renderer.svelte'

let backgrounds = []
</script>

<div class="slider">
  <Renderer />
  <div class="thumbnails">
    {#each backgrounds as wall}
      <Renderer />
    {/each}
  </div>
</div>

<style>
  .slider {
    width: 100%;
  }
</style>
