import { derived, writable } from 'svelte/store'
import matboards from './matboards'
import defaultMouldings from './mouldings'

function load(key) {
  try {
    let val = localStorage.getItem(key)
    if (!key.startsWith('framebuilder:')) {
      // remove old framerSku and framerUnit values
      localStorage.removeItem(key)
    }
    return val
  } catch(e) {
    return ''
  }
}

const mouldings = window.mouldings || defaultMouldings

function perMetre(price) {
  return price / 1000000
}

const defaultMouldingSku = mouldings[0].sku
const DEFAULT_SKU = `fk|${defaultMouldingSku}-700-900|white-mat-50-50-50|ab`
const DEFAULT_ACRYLIC_PRICE = 88
const DEFAULT_BACKBOARD_PRICE = 22
const MIN_MOULDING_LENGTH = 140
const MAX_MOULDING_LENGTH = 1020
const MIN_SIZE = location.search.indexOf('quote') > -1 ? -Infinity : MIN_MOULDING_LENGTH
const MAX_SIZE = location.search.indexOf('quote') > -1 ? Infinity : MAX_MOULDING_LENGTH
const MIN_MAT_WIDTH = location.search.indexOf('quote') > -1 ? -Infinity : 25

const initialUnit = load('framebuilder:unit') || load('framerUnit') || 'mm'
export const unit = writable(initialUnit) // 'mm', 'cm', or 'inch'

export const scale = derived(
  unit,
  $unit => ({
    mm: 1,
    cm: 10,
    inch: 25.4
  }[$unit])
)

export const precision = derived(
  unit,
  $unit => ({
    mm: 0,
    cm: 1,
    inch: 2
  }[$unit])
)

const defaultFrame = {
  mouldingSku: mouldings[0].sku,
  artWidth: 600,
  artHeight: 800,
  matSku: matboards[0].sku,
  matTop: 50,
  matBottom: 50,
  matSides: 50,
  hasPerspex: true,
  hasBackboard: true
}

function parseSku(sku) {
  if (!sku) return defaultFrame

  const pattern = /fk\|(.*)-(\d+)-(\d+)\|((.*)-(\d+)-(\d+)-(\d+))?\|(a)?(b)?/

  if (!pattern.test(sku)) {
    return defaultFrame
  }

  let [
    full,
    mouldingSku,
    mouldingWidth,
    mouldingHeight,
    fullMat,
    matSku,
    matTop,
    matBottom,
    matSides,
    acrylic,
    backboard
  ] = pattern.exec(sku)

  let artWidth
  let artHeight
  let hasPerspex = Boolean(acrylic)
  let hasBackboard = Boolean(backboard)

  if (matSku) {
    matTop = Number(matTop)
    matBottom = Number(matBottom)
    matSides = Number(matSides)
    artWidth = Number(mouldingWidth) - (2 * matSides)
    artHeight = Number(mouldingHeight) - matTop - matBottom
  } else {
    artWidth = Number(mouldingWidth)
    artHeight = Number(mouldingHeight)
  }

  try {
    return {
      mouldingSku,
      artWidth,
      artHeight,
      matSku,
      matTop,
      matBottom,
      matSides,
      hasPerspex,
      hasBackboard
    }
  } catch (e) {
    console.error(e)
    return defaultFrame
  }
}

const initialSku = load('framebuilder:sku') || load('framerSku') || DEFAULT_SKU
const saved = parseSku(initialSku)
const initialMouldingSku = window.mouldingSku || saved.mouldingSku || mouldings[0].sku || ''

export const frame = writable({
  artWidth: saved.artWidth || 600,
  artHeight: saved.artHeight || 800,
  mouldingSku: saved.mouldingSku || initialMouldingSku,     // undefined or empty means matte black moulding

  hasBackboard: saved.hasBackboard,
  hasPerspex: saved.hasPerspex,

  matSku: saved.matSku,
  matTop: saved.matTop || 50,
  matSides: saved.matSides || 50,
  matBottom: saved.matBottom || 50,
})

export const computedFrame = derived(
  frame,
  $frame => {
    let mouldingWidth = $frame.artWidth
    let mouldingHeight = $frame.artHeight
    if ($frame.matSku) {
      mouldingWidth += 2 * $frame.matSides
      mouldingHeight += $frame.matTop + $frame.matBottom
    }

    return Object.assign({}, $frame, {
      mouldingWidth,
      mouldingHeight,
    })
  }
)

export const error = derived(
  computedFrame,
  $frame => {
    let { mouldingWidth, mouldingHeight, matSku, matTop, matBottom, matSides } = $frame
    if (mouldingWidth < MIN_SIZE || mouldingHeight < MIN_SIZE) {
      return `Frame must be at least ${MIN_SIZE}mm`
    }

    if (mouldingWidth > MAX_SIZE || mouldingHeight > MAX_SIZE) {
      return `Frame can only be up to ${MAX_SIZE}mm`
    }

    if (matSku) {
      if (matTop < MIN_MAT_WIDTH || matBottom < MIN_MAT_WIDTH || matSides < MIN_MAT_WIDTH) {
        return `Mat must be at least ${MIN_MAT_WIDTH}mm wide`
      }
    }

    return ''
  }
)

export const valid = derived(
  error,
  $error => !$error
)

export const moulding = derived(
  frame,
  $frame => mouldings.find(m => m.sku === $frame.mouldingSku) || mouldings[0]
)

export const total = derived(
  [frame, valid],
  ([frame, valid]) => {
    if (!valid) {
      return ''
    }

    const moulding = mouldings.find(m => m.sku === frame.mouldingSku) || mouldings[0]
    const { price } = moulding

    let mouldingWidth = frame.artWidth
    let mouldingHeight = frame.artHeight
    if (frame.matSku) {
      mouldingWidth += 2 * frame.matSides
      mouldingHeight += frame.matTop + frame.matBottom
    }

    const basePrice = parseInt(window.vars.basePrice || 20)
    let pr = (2 * (mouldingWidth + mouldingHeight) * price / 1000) + basePrice

    if (frame.matSku) {
      const matPrice = 48 / 1000000
      pr += mouldingWidth * mouldingHeight * matPrice
    }

    const area = mouldingWidth * mouldingHeight

    if (frame.hasPerspex) {
      let acrylicPrice

      try {
        const acrylic = window.acrylics[0]
        acrylicPrice = perMetre(acrylic.price || DEFAULT_ACRYLIC_PRICE)
      } catch(e) {
        acrylicPrice = perMetre(DEFAULT_ACRYLIC_PRICE)
      }

      pr += acrylicPrice * area
    }

    if (frame.hasBackboard) {
      let bbPrice 

      try {
        const bb = window.backboards[0]
        bbPrice = perMetre(bb.price || DEFAULT_BACKBOARD_PRICE)
      } catch(e) {
        bbPrice = perMetre(DEFAULT_BACKBOARD_PRICE)
      }

      pr += bbPrice * area
    }

    return Math.ceil(parseFloat(pr)).toFixed(2)
  }
)

export const sku = derived(
  computedFrame,
  f => {
    let s = `fk|${f.mouldingSku}-${f.mouldingWidth}-${f.mouldingHeight}|`

    if (f.matSku) {
      s += `${f.matSku}-${f.matTop}-${f.matBottom}-${f.matSides}`
    }

    s += '|'

    if (f.hasPerspex) {
      s += 'a'
    }
    if (f.hasBackboard) {
      s += 'b'
    }

    return s
  }
)

function scaleFrame(frame, scale) {
  let mouldingWidth
  let mouldingHeight

  if (frame.matSku) {
    mouldingWidth = (frame.artWidth + 2 * frame.matSides) / scale
    mouldingHeight = (frame.artHeight + frame.matTop + frame.matBottom) / scale
  } else {
    mouldingWidth = frame.artWidth / scale
    mouldingHeight = frame.artHeight / scale
  }

  return {
    mouldingWidth,
    mouldingHeight,

    artWidth: frame.artWidth / scale,
    artHeight: frame.artHeight / scale,
    mouldingSku: frame.mouldingSku,

    hasBackboard: frame.hasBackboard,
    hasPerspex: frame.hasPerspex,

    matSku: frame.matSku,
    matTop: frame.matTop / scale,
    matSides: frame.matSides / scale,
    matBottom: frame.matBottom / scale,
  }
}

export const title = derived(
  [frame, scale, unit, precision],
  ([frame, scale, unit, $precision]) => {
    const scaled = scaleFrame(frame, scale)

    Object.keys(scaled).forEach(key => {
      try {
        scaled[key] = scaled[key].toFixed($precision).replace(/\.0+$/, '')
      } catch(e) {
        
      }
    })

    const { name } = mouldings.find(m => m.sku === frame.mouldingSku) || mouldings[0]
    let title = `${scaled.mouldingWidth}x${scaled.mouldingHeight}${unit} ${name}`

    if (frame.matSku) {
      const mat = matboards.find(m => m.sku === frame.matSku)
      title += ` + ${scaled.matTop}x${scaled.matBottom}x${scaled.matSides} ${mat.name} Matboard`
      title += ` w. ${scaled.artWidth}x${scaled.artHeight} window`
    }

    if (frame.hasPerspex) {
      title += ' + Acrylic & Backboard'
    } else if (frame.hasBackboard) {
      title += ' + Backboard'
    }

    return title
  }
)

